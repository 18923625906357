@font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/pacifico-v12-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Pacifico Regular'), local('Pacifico-Regular'),
       url('./fonts/pacifico-v12-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/pacifico-v12-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/pacifico-v12-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/pacifico-v12-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/pacifico-v12-cyrillic_latin-regular.svg#Pacifico') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-800.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-v15-cyrillic_cyrillic-ext_latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Pacifico", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  height: 100%; 
  display: flex;
  flex-direction: column;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
