@media (min-width: 900px) {
    .carousel {     
        height: 400px;
        max-width: none;
        
    }

    #offers {
        height: 550px;
    }
}

@media (min-width: 1000px) {
    .carousel {     
        height: 500px;
        max-width: none;
        
    }

    #offers {
        height: 650px;
    }
}
