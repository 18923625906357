.brand {
    max-height: 100px;    
}

.container{
    min-height: 100%;
    margin: 0 auto;
    flex: 1 0 auto;
}

.footer { 
  bottom: 0;
  flex-shrink: 0;
  margin-top: 20px;
}

.ReactModal__Overlay {
    height: 35rem;
    width: 25rem;
    padding: 5rem;
    align-items: center;
    display: flex;
    justify-content: center;  
    margin: auto;         
}

@media (min-width: 790px) {
    .ReactModal__Overlay {
        width: 40rem;
    }
}

.modalOpen {
    animation: openModal .4s ease-out forwards;
}

.modalClosed {
    animation: closeModal .4s ease-out forwards;
}

nav,
.final{   
    font-family: "Pacifico", cursive;
}

@keyframes openModal {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(20%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closeModal {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    50% {
        opacity: 0.7;
        transform: translateY(60%);        
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

#closeBtn {
    height: 1.5rem;
    font-size: 1rem;
    padding-top: 0;
}

.border-right {
    border-right: 1px solid #ddd;
}

.border-left {
    border-right: 1px solid #ddd;
}

.border-top {
    border-right: 1px solid #ddd;
}

.border-bottom {
    border-right: 1px solid #ddd;
}

.badge-notify{
    background: #d9534f;
    position:relative;
    top: 0px;
    left: -15px;
}

.text-link:visited{
    font-weight: normal;
}

@media (min-width: 780px) {
    .my-carousel {
        height: 450px;
        overflow: hidden;
    }
}

.page {
    background-color: white;
}

.selected-page {
    border-color: #d9534f;
}

.counter-container {
    display: none;
}

.searchBar {
    width: 25%;
}

@media (max-width: 750px) {
    .searchBar {
        width: 50%;
    }
}

@media (max-width: 600px) {
   #chefName {
        font-size: 2.5rem;
    }
}
